import axios from 'axios';
import { Registration } from '../@types/registration.d';
import { EventType } from '../@types/eventType.d';
import { Event } from '../@types/event.d';
import { Hold } from '../@types/hold.d';
import { IntentRequest } from '../@types/intentRequest.d';
import { PaymentIntent } from '../@types/paymentIntent.d';
import AppEmitter from './appEmitter';

const maxRetries = 3;
let retryCount = 0;

const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

axios.interceptors.response.use(
    async (response) => {
        retryCount = 0;
        return response;
    },
    async (error) => {
        if (error.toJSON) {
            let errorJson = error.toJSON();

            if (errorJson.message.indexOf('401') > 0) {
                if (retryCount > maxRetries) {
                    // Notify of permanent failure.
                    AppEmitter.emit('APPLICATION_AXIOS_AUTH_ERROR_DONE', {
                        retryCount,
                        error
                    });

                    return new Promise((reject) => {
                        reject(error);
                    })
                } else {
                    ++retryCount;
                    // Notify then retry.
                    AppEmitter.emit('APPLICATION_AXIOS_AUTH_ERROR', {
                        retryCount,
                        error
                    });

                    // Delay
                    await sleep(retryCount * 1000);

                    // Retry
                    const config = error.config;
                    let idToken = sessionStorage.getItem('idToken') || '';
                    config.headers = {
                        'Authorization': `Bearer ${idToken}`
                    }
                    return new Promise((resolve) => {
                        resolve(axios(config))
                    })
                }
            } else {
                AppEmitter.emit('APPLICATION_AXIOS_ERROR', errorJson);
            }
        } else {
            AppEmitter.emit('APPLICATION_AXIOS_ERROR', { error: error });
        }
        throw error;
    }
);

export const getCurrentHolds = async (accountId: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold?accountId=${accountId}`;
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            let currentHolds = await response.data as Array<Hold>;
            return Promise.resolve(currentHolds);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getEventByKey = async (eventKey: string, client: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Event/key?clientId=${client}&eventKey=${eventKey}`;
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(await response.data as Event);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getEventsByClient = async (client: string, cohort: string, includeEvents: boolean) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Event/type?clientId=${client}&includeEvents=${includeEvents}`;
        if (cohort) {
            url = url + `&group=${encodeURIComponent(cohort)}`;
        }
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(await response.data as Array<EventType>);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getEventsByClientAndType = async (client: string, type: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Event?clientId=${client}&type=${type}`;
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(await response.data as EventType);
        } else if (response.status === 204) {
            return Promise.resolve({} as EventType);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const addHold = async (accountId: string, clientId: string, esKey: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold?esKey=${esKey}&clientId=${clientId}&accountId=${accountId}`;
        let response = await axios.post(url, null, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 201) {
            return Promise.resolve(true);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const validateHold = async (accountId: string, clientId: string, esKey: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold/Validate?accountId=${accountId}&clientId=${clientId}&esKey=${esKey}`;
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(true);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const removeHold = async (accountId: string, esKey: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold/Remove?accountId=${accountId}&esKey=${esKey}`;
        let response = await axios.delete(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(true);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const confirmHold = async (accountId: string, holdId: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold?accountId=${accountId}&holdId=${holdId}`;
        let response = await axios.put(url, null, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200 || response.status === 409) {
            return Promise.resolve(true);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const createPaymentIntent = async (accountId: string, intentOptions: IntentRequest) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Hold/create-payment-intent?accountId=${accountId}`;
        let response = await axios.post(url, intentOptions, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200) {
            return Promise.resolve(await response.data as PaymentIntent);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const createCancelRequest = async (accountId: string, registration: any) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Event/canceltransfer?accountId=${accountId}`;
        let response = await axios.post(url, registration, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 201) {
            return Promise.resolve(true);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

export const getRegistrations = async (accountId: string, clientId: string) => {
    try {
        let idToken = sessionStorage.getItem('idToken') || '';
        let url = `${process.env.REACT_APP_API_HOST}/registration-api/api/Event/registrations?accountId=${accountId}&clientId=${clientId}`;
        let response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        if (response.status === 200 || response.status === 204) {
            return Promise.resolve(await response.data as Array<Registration>);
        } else {
            return Promise.reject(response.statusText);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}