import { Fragment } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import moment from 'moment';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { Loading } from '../components';

const WhoAmI: React.FC = () => {
    const { user } = useAuth0();

    let idToken = sessionStorage.getItem('idToken') || '';

    return (
        <Fragment>
            <Card className='card card-custom gutter-b card-stretch'>
                <Card.Header>
                    <div className='card-title font-weight-bolder'>
                        <div className='card-label'>Who are you?
                            <div className='font-size-sm text-muted mt-2'>{moment().format('MMM Do YYYY')}</div>
                        </div>
                    </div>
                    <div className='card-toolbar'>
                        <div className='dropdown dropdown-inline'>
                            <a href='#/' className='btn btn-clean btn-hover-light-primary btn-sm btn-icon' data-toggle='dropdown' aria-expanded='false'>
                                <i className='ki ki-bold-more-ver'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-md dropdown-menu-right'>
                                <ul className='navi'>
                                    <li className='navi-header font-weight-bold py-5'>
                                        <span className='font-size-lg'>User Menu</span>
                                        <i className='flaticon2-information icon-md text-muted' data-toggle='tooltip' data-placement='right' title='' data-original-title='Click to learn more...'></i>
                                    </li>
                                    <li className='navi-separator mb-3 opacity-70'></li>
                                    <li className='navi-item'>
                                        <a href='#/' className='navi-link'>
                                            <span className='navi-icon'>
                                                <i className='navi-icon flaticon2-telegram-logo'></i>
                                            </span>
                                            <span className='navi-text'>Project</span>
                                        </a>
                                    </li>
                                    <li className='navi-separator mt-3 opacity-70'></li>
                                    <li className='navi-footer pt-5 pb-4'>
                                        <Button className='btn-light-primary font-weight-bolder btn-sm'>Do Something</Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className='text-left'>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Environment:</label></Col>
                        <Col sm='12' md='9'>{process.env.NODE_ENV}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Nickname:</label></Col>
                        <Col sm='12' md='9'>{user?.nickname}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Name:</label></Col>
                        <Col sm='12' md='9'>{user?.name}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Picture:</label></Col>
                        <Col sm='12' md='9'>{user?.picture}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Updated_at:</label></Col>
                        <Col sm='12' md='9'>{user?.updated_at}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Email:</label></Col>
                        <Col sm='12' md='9'>{user?.email}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Email_verified:</label></Col>
                        <Col sm='12' md='9'>{user?.email_verified ? 'Yes' : 'No'}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Sub:</label></Col>
                        <Col sm='12' md='9'>{user?.sub}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Process:</label></Col>
                        <Col sm='12' md='9'>{JSON.stringify(process?.env)}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Environment:</label></Col>
                        <Col sm='12' md='9'>{process.env.REACT_APP_ENVIRONMENT}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Full Token:</label></Col>
                        <Col sm='12' md='9'>
                            <a rel="noreferrer" href={"https://jwt.io/?token=" + idToken} target="_blank">View on JWT.io</a>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm='12' md='3' className='text-md-right text-left'><label>Claims:</label></Col>
                        {
                            user &&
                            <Col sm='12' md='9'>
                                <Row className='mb-3'>
                                    <Col md='3' className='text-md-right text-left'><label>Enterprise ID:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/ccl-identity/id']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Given Name:</label></Col>
                                    <Col md='9'>{user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Surname:</label></Col>
                                    <Col md='9'>{user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Email Address:</label></Col>
                                    <Col md='9'>{user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>GC Account ID:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/account/id']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>GC User ID:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/user/id']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Enabled:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/user/enabled']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Archived:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/user/archived']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Tenant ID:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/tenant/id']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Groups:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/group/ids']}</Col>
                                    <Col md='3' className='text-md-right text-left'><label>Client ID:</label></Col>
                                    <Col md='9'>{user['http://schemas.ccl.org/accounts/claims/client/id']}</Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default withAuthenticationRequired(WhoAmI, {
    onRedirecting: () => <Loading />
});
